import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { STATUS_OPEN_TO_AGENT } from '../../../../../config/portCall';
import Button from '../../../../layout/components/Button';
import { selectGeneralData, selectMissingFields, updatePortCallStatus } from '../../../slices/portCallSlice';
import { generatePreArrivalDocuments } from '../../documents/slices/documentsSlice';
import SendConfirmationModal from './SendConfirmationModal';
import '../styles/instructions.scss';

export const SendFormLayout = function () {
  const dispatch = useDispatch();
  const params = useParams();
  const generalPortcallData = useSelector(selectGeneralData);
  const { status } = generalPortcallData;
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const onSendFormClick = () => {
    const payload = {
      uid: params.uid,
      newStatus: STATUS_OPEN_TO_AGENT,
    };
    dispatch(generatePreArrivalDocuments({ uid: params.uid }));
    dispatch(updatePortCallStatus(payload));
    setIsConfirmationModalOpen(true);
  };

  return (
    <>
      <SendConfirmationModal
        isConfirmationModalOpen={isConfirmationModalOpen || status === STATUS_OPEN_TO_AGENT}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
      />
      <Button className='generate-form-btn' onClick={onSendFormClick}>
        Send to agent
      </Button>
    </>
  );
};

export default SendFormLayout;

import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import "./styles/style.css"
import Button from "../../layout/components/Button";
import {CrossCircleIcon} from "./styles/CrossCircle";
import {CheckCircleIcon} from "./styles/CheckCircle";
import {WarningIcon} from "./styles/Warning";
import {CrossXIcon} from "./styles/CrossX";
import {InformationIcon} from "./styles/Information";

/**
 * The `NotificationAlert` component is where show a Notification message
 */

const colors = {
    information:{
        100: '#F2F8FF',
        200: '#79B1FF',
        500: '#79B1FF',
        600: '#006AFF',
    },
    error: {
        50: '#FFF4F4',
        100: '#fce3e3',
        200: '#ffcfcf',
        500: '#EB0000',
        600: '#B61A1A',
    },
    success: {
        50: '#F4FFF5',
        200: '#D1F0D2',
        300: '#43F074',
        500: '#66bb6A',
        600: '#358A57',
    },
    warning: {
        100: '#FEF3E2',
        200: '#FDE68A',
        500: '#F59E0B',
        600: '#B45309',
    },
}
const colorMap = {
    information:{
        backgroundColor: colors.information["100"],
        color: colors.information["600"],
        borderColor:  colors.information["600"],
        borderStyle: 'solid',
        borderWidth: 1,
    },
    error: {
        backgroundColor: colors.error["50"],
        color: colors.error["600"],
        borderColor:  colors.error["600"],
        borderStyle: 'solid',
        borderWidth: 1,
    },
    warning: {
        backgroundColor: colors.warning["100"],
        color: colors.warning["600"],
        borderColor: colors.warning["600"],
        borderStyle: 'solid',
        borderWidth: 1,
    },
    success: {
        backgroundColor: colors.success["50"],
        color: colors.success["600"],
        borderColor: colors.success["600"],
        borderStyle: 'solid',
        borderWidth: 1,
    },
};

const colorType = {
    information:{borderColor: colors.information["600"]},
    error: {borderColor: colors.error["600"]},
    success: {borderColor: colors.success["600"]},
    warning: {borderColor: colors.warning["600"] },
};


export const NotificationAlert  = ({
    id = '',
    alertType = 'error',
    isOpened = false,
    setIsOpened,
    title = '',
    message = '',
    autoClose = false,
    timeAutoClose = 5000,
    showCloseButton = true,
    ...props
}) => {
    const alert = useRef(null);

    useEffect(() => {
        alert.current = document.createElement('div')
        document?.body?.appendChild(alert?.current);
    }, []);

    useEffect(() => {
        alert.current.id = id;
        const { body } = document;
        if (isOpened){
            body.appendChild(alert.current);
            if (isOpened && autoClose) {
                setTimeout(() => {
                    setIsOpened(false);
                }, timeAutoClose);
            }
        } else {
            body.removeChild(alert.current);
        }
    }, [autoClose, id, isOpened, setIsOpened, timeAutoClose]);

    useEffect(()=>{
        return ()=>{

        }
    },[])

    return (
        alert.current &&
        ReactDOM.createPortal(
            <div
                className={`alertModal flex`}
                id={id}
                style={{
                    transitionDuration: '0.5s',
                    transitionProperty: 'opacity, transform, display',
                    willChange: 'opacity, transform, display',
                    transform: !isOpened ? 'translateX(100%)' : 'translateX(0%)',
                    opacity: isOpened ? 0.98 : 0,
                    ...colorMap[alertType]
                }}
                {...props}
            >
                <i style={{width: "20px", height: "20px", margin: "10px"}}>
                    {alertType === 'error' && <CrossCircleIcon fill={colors[alertType]["600"]} />}
                    {alertType === 'warning' && <WarningIcon fill={colors[alertType]["600"]} />}
                    {alertType === 'success' && <CheckCircleIcon fill={colors[alertType]["600"]} />}
                    {alertType === 'information' && <InformationIcon fill={colors[alertType]["600"]} />}
                </i>
                <div style={{ flex: 2 }}>
                    <p style={{fontWeight:"bold", margin: 2, fontSize: "16px"}}>
                        {title}
                    </p>
                    <p style={{fontWeight:"light", margin: 2, fontSize: "14px"}}>
                        {message}
                    </p>
                </div>
                {showCloseButton && (<div className={"flex"} style={{alignSelf: "center"}}>
                    <Button
                        onClick={() => setIsOpened && setIsOpened(false)}
                        style={colorType[alertType]}
                        className={`button ${alertType}`}
                    >
                        <CrossXIcon stroke={colors[alertType]["600"]}/>
                    </Button>
                </div>)}
            </div>,
            alert.current
        )
    );
}

import { faSpinner, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { PDFDocument } from 'pdf-lib';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { API_REQUEST_FORMAT_DATE } from '../../../../global/utils/datetimes';
import Modal from '../../../../layout/components/Modal';
import {
  downloadDocument,
  getDocument,
  removeUploadedDocument,
  selectIsError,
  selectIsLoading,
  selectUploadedDocument,
  uploadDocument,
  uploadLocalFormalities,
} from '../../documents/slices/documentsSlice';
import documentIcon from '../styles/images/document-icon.svg';
import downloadIcon from '../styles/images/download-icon.svg';
import uploadIconCloud from '../styles/images/upload-icon-cloud.svg';
import uploadIcon from '../styles/images/upload-icon.svg';

import { Field } from '@navozyme/uikit/dist/atoms/Field';
import { DatePickerTimeInput } from '@navozyme/uikit/dist/molecules/DatePicker';
import { isDateExpired, MandatoryFieldMessages } from '../../../validations/FieldsValidation';
import { CERTIFICATES, DOCUMENT_TYPE_IDS } from '../../documents/constants';
import { NotificationAlert } from "../../../../global/alertModal/alertModal";
import '../styles/upload-download-box.scss';

const MAX_FILE_NAME = 256;

async function isPDFPasswordProtected(file) {
  try {
    const arrayBuffer = await file.arrayBuffer();
    await PDFDocument.load(arrayBuffer);
    return false;
  } catch (error) {
    return error.message.includes("encrypted") || error.message.includes("password");
  }
}

function UploadDownloadBox({ children, document, category, isMandatory, callback, isShipCertificate = false }) {
  const dispatch = useDispatch();
  const params = useParams();
  const { documentUid, templateUid } = document;

  const isLoading = useSelector((state) => selectIsLoading(state, document.documentType.id));
  const isLoadingLocalFormality = useSelector((state) => selectIsLoading(state, document?.templateUid));
  const isError = useSelector((state) => selectIsError(state, document.documentType.id));

  const { document: uploadedDocument } = useSelector((state) => selectUploadedDocument(state, documentUid));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRemoveFileConfirmationModalOpen, setIsRemoveFileConfirmationModalOpen] = useState(false);

  const [fileToUpload, setFileToUpload] = useState(null);
  const [issueDate, setIssueDate] = useState(null);

  const [isErrorDisplaying, setIsErrorDisplaying] = useState(isError);
  
  useEffect(() => {
    setIsErrorDisplaying(isError);
  }, [isError]);

  const shouldShowNextStep = category === 'requestedCertificates' && fileToUpload !== null;

  async function handleUploadDocument(event) {
    const file = event.target.files[0];
    if (file.name.length > MAX_FILE_NAME) {
      setIsErrorDisplaying({message: "File name is too long. Please rename the file and try again."});
      return;
    }

    const isProtected = await isPDFPasswordProtected(file);
    if (isProtected) {
      setIsErrorDisplaying({message: "This file is password-protected. Remove the password and try again."});
      return;
    }

    if (category === 'requestedCertificates') {
      setFileToUpload(file);
    } else {
      if (document?.documentType?.id === DOCUMENT_TYPE_IDS.LOCAL_FORMALITY_OTHER) {
        dispatch(
          uploadLocalFormalities({
            file,
            documentUid: uuidv4(),
            documentTypeId: document.documentType.id,
            category,
            portcallId: params.uid,
            documentSubCategory: document?.documentType.subcategory,
            documentTypeName: document?.documentType.otherName,
            templateUid: document?.templateUid,
          })
        );
      } else {
        dispatch(
          uploadDocument({
            file,
            documentUid: uuidv4(),
            documentTypeId: document.documentType.id,
            category,
            portcallId: params.uid,
            documentTypeName: document.documentType.name,
            isShipCertificate: isShipCertificate,
          })
        );
      }
      setIsModalOpen(false);
    }
  }

  useEffect(() => {
    if (documentUid && !uploadedDocument) {
      dispatch(
        getDocument({
          documentTypeId: document.documentType.id,
          uiid: documentUid,
        })
      ).then((_response) => {
        if (document?.documentType?.id === CERTIFICATES.ISO_14001.id) callback();
      });
    }
  }, [documentUid]);

  function renderLastUpdatedAt() {
    if (uploadedDocument && uploadedDocument.updatedAt) {
      return (
        <span className='upload-download-box__last-updated'>
          Last Updated: {format(new Date(uploadedDocument.updatedAt), 'yyyy MMMM dd') || '--'}
        </span>
      );
    }

    return <span className='upload-download-box__last-updated'>Last Updated: --</span>;
  }

  function handleDownload() {
    dispatch(
      downloadDocument({
        uiid: templateUid,
        fileName: children,
      })
    );
  }

  function hanldeRemoveDocument() {
    dispatch(
      removeUploadedDocument({
        portcallId: params.uid,
        documentUid: document.documentUid,
        category,
        documentTypeId: document.documentType.id,
        templateUid: document?.templateUid,
      })
    );

    setIsRemoveFileConfirmationModalOpen(false);
  }

  function renderRightSide() {
    if (isLoading || isLoadingLocalFormality) {
      return <FontAwesomeIcon spin icon={faSpinner} className='loader' size='3x' style={{ color: '#5b8fe9' }} />;
    }

    if (!_.isEmpty(uploadedDocument)) {
      return (
        <>
          <div className='file-name'>
            <span className='file-name__name'>File uploaded</span>
            <span className='file-name__x-mark' onClick={() => setIsRemoveFileConfirmationModalOpen(true)}>
              <FontAwesomeIcon icon={faXmark} />
            </span>
          </div>
        </>
      );
    } else {
      return (
        <>
          {templateUid && (
            <button className='download-btn' onClick={handleDownload}>
              <img src={downloadIcon} alt='downloadIcon' />
              Download
            </button>
          )}
          <button className='upload-btn' onClick={() => setIsModalOpen(true)}>
            <img src={uploadIcon} alt='uploadBtn' />
            Upload
          </button>
        </>
      );
    }
  }

  function handleSubmit(values) {
    dispatch(
      uploadDocument({
        documentUid: uuidv4(),
        portcallId: params.uid,
        category,
        documentTypeId: document.documentType.id,
        documentTypeName: document.documentType.name,
        file: fileToUpload,
        expiryDate: format(values.expiryDate, API_REQUEST_FORMAT_DATE),
        issueDate: format(values.issueDate, API_REQUEST_FORMAT_DATE),
        isShipCertificate: isShipCertificate,
        documentCategory: document?.documentType?.category,
        documentSubCategory: document?.documentType?.subcategory,
      })
    );
    setIsModalOpen(false);
    setTimeout(() => {
      setFileToUpload(null);
    }, 300);
  }

  const initialValues = {
    issueDate: null,
    expiryDate: null,
  };
  const validationSchema = Yup.object({
    issueDate: Yup.string().required('*This field is required'),
    expiryDate: Yup.string().required('*This field is required'),
  });

  return (
    <div className='upload-download-box'>
      <NotificationAlert 
        isOpened={isErrorDisplaying}
        setIsOpened={setIsErrorDisplaying}
        id='alert-notification'
        alertType="error"
        title="Error"
        message={isErrorDisplaying?.message}
        timeAutoClose={5000}
        autoClose={true}
      />
      <div className='upload-download-box__top'>
        <div className='upload-download-box__left'>
          <div>
            <img src={documentIcon} alt='documentIcon' />
          </div>
          <div>
            <h5>{children}</h5>
            {renderLastUpdatedAt()}
          </div>
        </div>
        <div className='upload-download-box__right'>{renderRightSide()}</div>
      </div>
      {isMandatory && !document.documentUid && (
        <span className='required-document-label'>{MandatoryFieldMessages.RequiredDocument}</span>
      )}
      <div className='upload-download-box__bottom'>
        {document.issueDate && (
          <div className='upload-download-box__certificate-date'>
            Issue: {format(new Date(document.issueDate), 'yyyy-MMMM-dd')}
          </div>
        )}
        {document.expiryDate && !isDateExpired(document.expiryDate) && (
          <div className='upload-download-box__certificate-date'>
            Expiry: {format(new Date(document.expiryDate), 'yyyy-MMMM-dd')}
          </div>
        )}
        {document.expiryDate && isDateExpired(document.expiryDate) && (
          <div className='upload-download-box__expired-certificate-date'>
            Expiry: {format(new Date(document.expiryDate), 'yyyy-MMMM-dd')}
          </div>
        )}
      </div>

      <Modal
        className={`add-certificate-modal`}
        showModal={isModalOpen}
        hideCloseBtn
        onCloseModal={() => {
          setIsModalOpen(false);
        }}>
        <div className='add-certificate-modal__title'>
          <img src={uploadIconCloud} alt='uploadIconCloud' />
          <div>
            <h3>Upload a certificate</h3>
            {shouldShowNextStep ? (
              <p>Complete the required certificate data.</p>
            ) : (
              <p>Upload one or multiple certificates.</p>
            )}
          </div>
        </div>
        {shouldShowNextStep ? (
          <>
            <div>
              <Formik
                onSubmit={handleSubmit}
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}>
                {({ values, errors, setValues }) => (
                  <>
                    <Form className='certificate-dates-form'>
                      <div className='certificate-dates-form__controls'>
                        <div className='certificate-dates-form__row'>
                          <Field label='Issuance date' className='field_date_picker'>
                            <DatePickerTimeInput
                              key={initialValues?.issueDate + 'issueDate'}
                              name='issueDate'
                              sizeInput='large'
                              maxDate={new Date()}
                              defaultValue={initialValues?.issueDate}
                              action={({ isoDate }) => {
                                setIssueDate(new Date(isoDate));
                                setValues({ ...values, issueDate: new Date(isoDate) });
                              }}
                              hasError={errors?.issueDate}
                              errorMessage={errors?.issueDate}
                            />
                          </Field>
                          <Field label='Expiry date' className='field_date_picker'>
                            <DatePickerTimeInput
                              key={initialValues?.expiryDate + 'expiryDate'}
                              name='expiryDate'
                              sizeInput='large'
                              defaultValue={initialValues?.expiryDate}
                              action={({ isoDate }) => setValues({ ...values, expiryDate: new Date(isoDate) })}
                              minDate={issueDate || initialValues?.issueDate}
                              hasError={errors?.expiryDate}
                              errorMessage={errors?.expiryDate}
                            />
                          </Field>
                        </div>
                      </div>
                      <div className='file-name'>{fileToUpload && fileToUpload.name}</div>
                      <div className='certificate-dates-form__control-btns'>
                        <button
                          type='button'
                          className='certificate-dates-form__back-btn'
                          onClick={() => {
                            setIsModalOpen(false);
                            setTimeout(() => {
                              setFileToUpload(null);
                            }, 500);
                          }}>
                          Back
                        </button>
                        <button type='submit' className='certificate-dates-form__save-btn'>
                          Save
                        </button>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </>
        ) : (
          <>
            <div className='add-certificate-modal__upload-section'>
              <label htmlFor='file-upload' className='custom-file-upload'>
                <input
                  id='file-upload'
                  type='file'
                  accept='.pdf'
                  onChange={handleUploadDocument}
                  aria-label={'Upload from your device'}
                />
                Upload from your device
              </label>
            </div>
            <div className='add-certificate-modal__back-btn' onClick={() => setIsModalOpen(false)}>
              Back
            </div>
          </>
        )}
      </Modal>
      <Modal
        className={`remove-file-confirmation-modal`}
        showModal={isRemoveFileConfirmationModalOpen}
        hideCloseBtn
        onCloseModal={() => {
          setIsRemoveFileConfirmationModalOpen(false);
        }}>
        <div className='remove-file-confirmation-modal__title'>Are you sure you want to remove this file?</div>
        <div className='remove-file-confirmation-modal__subtitle'>You will be able to uploaded it again later.</div>
        <div className='remove-file-confirmation-modal__control-btns'>
          <button
            className='remove-file-confirmation-modal__cancel-btn'
            onClick={() => setIsRemoveFileConfirmationModalOpen(false)}>
            Cancel
          </button>
          <button className='remove-file-confirmation-modal__remove-btn' onClick={hanldeRemoveDocument}>
            Remove
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default UploadDownloadBox;
